import { KnackConfigs } from "./knackApiTemplates";
import {
  FORM_CONFIG,
  CLEAR_FORM_CONFIG,
  DETAIL_HEADER_CONFIG,
} from "../constants/knackConfig";
import store2 from "store2";
import { knackRoles } from "../../utils/KnackAndFrontEndRel";
import { backendUrl } from "../../utils/config";
import { store } from "../storeConfig";
import { apiCall } from "./api";
import { METHODS } from "./knack/types";
import { ConfigCampos, ConfigGrupos, Contrato } from "../../utils/knackKeys";
import { fetchRecordError } from "./errors";

const SPECIAL_CASES_FM = [Contrato.fields.DIPLOMA, Contrato.fields.PASSAPORTE_QUALIFICA, Contrato.fields.CERTIFICADO]

const {
  fields: {
    FIELD_ID,
    POSITION_IN_DETAIL,
    GROUP,
    TYPE,
    POSITION,
    SHOW_IN_DETAIL,
    POSITION_IN_HEADER,
    LINE_IN_HEADER,
    CAN_EDIT,
    CAN_VIEW,
    CAN_CLICK_CONNECTED,
    TYPE_IDENTIFIER,
    FORCE_HIDE_EDIT,
    FORCE_HIDE_CREATE,
    ALLOW_UPLOAD_IF_EMPTY,
    DISABLE_UPLOAD,
    ALTERNATIVE_INFORMATION,
    DISPLAY_ALTERNATIVE_INFORMATION_TO_PROFILES
  },
} = ConfigCampos;

const {
  fields: { POSITION: POSITION_GROUP, DISPLAY },
} = ConfigGrupos;

export const clearFormConfig = () => (dispatch) => {
  dispatch({
    type: CLEAR_FORM_CONFIG,
  });
};

export const fetchDetailHeaderConfig = (object) => async (dispatch) => {
  try {
    let storedHeaderFields = store2(`detail_header_config_${object}`);

    if (storedHeaderFields) {
      dispatch({
        type: DETAIL_HEADER_CONFIG,
        payload: storedHeaderFields,
      });
      return;
    }

    const getHeaderFields = await configApi({
      url: KnackConfigs(
        "fieldConfig-ObjectBased",
        ConfigCampos.object,
        object,
        [POSITION_IN_HEADER, "is not blank"]
      ),
    });
    const fieldsConfig = await configApi({
      url: KnackConfigs("entityDefinitions-ObjectBased", object),
    });

    let headerFields = [];

    let getHeaderFieldsData = getHeaderFields.data.records;
    let fieldsConfigData = fieldsConfig.data.object.fields;

    getHeaderFieldsData.forEach((fieldData) => {
      if (!fieldData[POSITION_IN_HEADER] || !fieldData[LINE_IN_HEADER]) return;
      fieldsConfigData.forEach((field) => {
        if (fieldData[FIELD_ID] !== field.key) return;
        const currentLine = headerFields.find(
          (el) => el.line === fieldData[LINE_IN_HEADER]
        );
        if (!currentLine)
          return headerFields.push({
            line: fieldData[LINE_IN_HEADER],
            fields: [{ ...field, position: fieldData[POSITION_IN_HEADER] }],
          });
        field.position = fieldData[POSITION_IN_HEADER];
        currentLine.fields.push(field);
      });
    });

    headerFields.sort((a, b) => a.line - b.line);
    headerFields.forEach((line) =>
      line.fields.sort((a, b) => a.position - b.position)
    );

    store2(`detail_header_config_${object}`, headerFields);

    dispatch({
      type: DETAIL_HEADER_CONFIG,
      payload: headerFields,
    });
  } catch (error) {
    console.log("erro no fetchDetailHeaderConfig", error);
    fetchRecordError({
      message: "Erro ao carregar o cabeçalho da página, faça refresh",
    })(dispatch);
  }
};

export const fetchFormConfig = (object) => async (dispatch) => {
  try {
    let groupsConfigData = store2(`form_config_${object}`);
    if (groupsConfigData) {
      // if (groupsConfigData?.length) {
      dispatch({
        type: FORM_CONFIG,
        payload: groupsConfigData,
      });
      return;
    }

    const groupsConfig = await configApi({
      url: KnackConfigs("groupConfig-ObjectBased", ConfigGrupos.object, object),
    });
    const insideGroupsConfig = await configApi({
      url: KnackConfigs("fieldConfig-ObjectBased", ConfigCampos.object, object),
    });
    const fieldsConfig = await configApi({
      url: KnackConfigs("entityDefinitions-ObjectBased", object),
    });

    let groupsConfigResponse = groupsConfig.data.records;
    let insideGroupsConfigResponse = insideGroupsConfig.data.records;
    let fieldsConfigResponse = fieldsConfig.data.object.fields;

    groupsConfigResponse.forEach((group) => {
      group.fields = [];
      insideGroupsConfigResponse.forEach((fieldGroup) => {
        if (fieldGroup[SHOW_IN_DETAIL] === true) {
          if (fieldGroup[GROUP]?.[0]?.id === group.id) {
            fieldsConfigResponse.forEach((field) => {
              if (fieldGroup[FIELD_ID] === field.key) {
                field.position = fieldGroup[POSITION_IN_DETAIL];
                field.canEdit = fieldGroup[CAN_EDIT];
                field.canView = fieldGroup[CAN_VIEW];
                field.hideInEdit = fieldGroup[FORCE_HIDE_EDIT];
                field.hideInCreate = fieldGroup[FORCE_HIDE_CREATE];
                field.canClickConnected = fieldGroup[CAN_CLICK_CONNECTED];
                field.identifierType = fieldGroup[TYPE_IDENTIFIER];
                if (fieldGroup[TYPE] === "file") {
                  field.type = "file";
                  field.forceAllowUpload = fieldGroup[ALLOW_UPLOAD_IF_EMPTY];
                  field.isUploadDisabled = fieldGroup[DISABLE_UPLOAD];
                  if (field.isUploadDisabled) field.alternativeInformation = fieldGroup[ALTERNATIVE_INFORMATION]
                }
                if (fieldGroup[TYPE] === "user_roles") {
                  field.format = {
                    options: knackRoles,
                    type: "multi",
                  };
                }
                if (SPECIAL_CASES_FM.includes(field.key)) {
                  field.displayAlternativeInformationToProfiles = fieldGroup[DISPLAY_ALTERNATIVE_INFORMATION_TO_PROFILES]
                }

                group.fields.push(field);
              }
            });
          }
        }
      });

      group.fields.sort((a, b) => a.position - b.position);
    });

    const groupsToDisplay = groupsConfigResponse?.filter(
      (group) => !!group[DISPLAY]
    );
    const sortedGroups = groupsToDisplay.sort(
      (a, b) => a?.[POSITION_GROUP] - b?.[POSITION_GROUP]
    );

    store2(`form_config_${object}`, sortedGroups);

    dispatch({
      type: FORM_CONFIG,
      payload: sortedGroups,
    });
  } catch (error) {
    console.log("erro no fetch Form Config", error);
    fetchRecordError({ message: "Erro ao carregar a página, faça refresh" })(
      dispatch
    );
  }
};

export const fetchGridConfig = (object) => async (dispatch) => {
  try {
    let finalGridConfig = store2(`grid_config_${object}`);

    if (finalGridConfig) return finalGridConfig;
    else {
      const objectFields = await configApi({
        url: KnackConfigs("entityDefinitions-ObjectBased", object),
      });
      const fieldsToShowInTable = await configApi({
        url: KnackConfigs(
          "gridFieldsConfig-ObjectBased",
          ConfigCampos.object,
          object
        ),
      });

      let objectFieldsData = objectFields.data.object.fields;
      let objectFieldsToShowInTableData = fieldsToShowInTable?.data?.records;

      let gridConfig = [];

      objectFieldsData.forEach((column) => {
        objectFieldsToShowInTableData.forEach((field) => {
          let { key, type, format } = column;
          let forceAllowUpload, isUploadDisabled, alternativeInformation;
          if (field[ConfigCampos.fields.FIELD_ID] !== key) return;
          if (field[TYPE] === "file") {
            type = "file";
            forceAllowUpload = field[ALLOW_UPLOAD_IF_EMPTY]
            isUploadDisabled = field[DISABLE_UPLOAD]
            if (isUploadDisabled) alternativeInformation = field[ALTERNATIVE_INFORMATION]
          }
          switch (type) {
            case "phone":
              key = `${key}.formatted`;
              break;
            case "email":
              key = `${key}.email`;
              break;
            case "date_time":
              key = `${key}.date_formatted`;
              break;
            case "equation":
              if (format?.equation_type === "date")
                key = `${key}.date_formatted`;
              else key = `${key}`;
              break;
            case "address":
              key = `${key}`;
              break;
            case "user_roles":
              format = {
                options: knackRoles,
                type: "multi",
              };
              break;
            default:
          }
          let fieldStructure = {
            ...column,
            position: field[POSITION],
            canView: field[CAN_VIEW],
            canEdit: field[CAN_EDIT],
            identifierType: field[TYPE_IDENTIFIER],
            type,
            key,
            format,
          }
          if (field[TYPE] === "file") fieldStructure = {
            ...fieldStructure,
            forceAllowUpload,
            isUploadDisabled,
            alternativeInformation
          }
          gridConfig.push(fieldStructure);
        });
      });
      gridConfig.push({
        type: "id",
        name: "Abrir",
        key: "id",
        position: 1.5,
      });

      finalGridConfig = gridConfig?.filter(
        ({ type }) => !["image", "password"].includes(type)
      );

      finalGridConfig.sort((a, b) => a.position - b.position);

      finalGridConfig[0].checkBox = true;

      store2(`grid_config_${object}`, finalGridConfig);

      return finalGridConfig;
    }
  } catch (error) {
    fetchRecordError({
      message: "Erro ao carregar a configuração da tabela, faça refresh",
    })(dispatch);
  }
};

export const configApi = async ({ method = METHODS.GET.value, url }) => {
  try {
    const userJwtToken = getAuthCache().token;

    return await apiCall({
      action: METHODS.POST.value,
      url: `${backendUrl}/knack/config`,
      data: { method, url },
      token: userJwtToken,
    });
  } catch (error) {
    console.log("error in configApi", error);
    throw error;
  }
};

export const getAuthCache = () => {
  const auth = store.getState()?.auth;
  if (auth) return auth;

  const persistedCache = store2("persist:app");
  if (!persistedCache?.auth) return {};

  const authCache = JSON.parse(persistedCache.auth);
  return authCache;
};
